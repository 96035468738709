<template>
  <div>
    <div class="head">
      <div class="title">{{ $t("events.tickets.view.headline") }}</div>
      <div class="subtitle">
        <router-link
            :to="{name: 'event-tickets', params:{ eventId: this.$route.params.eventId }}">
          <span>{{ $t('events.tickets.create.viewAllTickets') }}</span>
        </router-link>
      </div>
    </div>
    <div class="main">
      <mu-paper :z-depth="1">
        <div
          class="ticket-description"
          v-if="ticketInfo.title">
          {{ $t("events.tickets.view.description") }}
        </div>
        <div
            class="ticket-image"
            v-if="ticketInfo.file_url">
          <img :src="ticketInfo.file_url" alt="" />
        </div>
        <div
            class="ticket-dates"
            v-if="ticketInfo.date_start"
        >
          <span class="ticket-date-from">{{ ticketInfo.date_start }}</span>
          <span v-if="ticketInfo.date_end" class="ticket-date-to"> — {{ ticketInfo.date_end }}</span>
        </div>
      </mu-paper>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'view-ticket',
  components: {},
  computed: {
    ...mapState(
      "ticketsStore", ["ticketInfo"]
    ),
  },
  watch: {
    ticketInfo: {
      deep: true,
      handler() {
      }
    }
  },
  data: () => ({}),
  created() {
    this.$store.dispatch('ticketsStore/viewTicket', this.$route.params.id_ticket);
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
</style>
